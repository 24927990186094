import { Button, ButtonProps, Confirm, ConfirmProps, useNotify, useRefresh } from "react-admin";
import { MutationFunction, useMutation } from "@tanstack/react-query";
import React, { MouseEventHandler, useState } from "react";

export interface ConfirmDialogButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataProviderAction: MutationFunction<any, void>;
  buttonProps: Omit<ButtonProps, "onClick">;
  confirmProps: Omit<ConfirmProps, "onConfirm" | "onClose" | "isOpen" | "loading">;
  successMessage: string;
}

export function ConfirmButton({
  dataProviderAction,
  buttonProps,
  confirmProps,
  successMessage,
}: ConfirmDialogButtonProps) {
  const refresh = useRefresh();
  const notify = useNotify();
  const { mutate, isPending } = useMutation({
    mutationFn: dataProviderAction,
    onSuccess: () => {
      refresh();
      notify(successMessage, { type: "success" });
    },
    onError: (error: Error) => notify(error.message, { type: "error" }),
  });
  const [open, setOpen] = useState(false);
  const handleClick: MouseEventHandler = (e) => {
    setOpen(true);
    e.stopPropagation();
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick} {...buttonProps} />
      <Confirm
        isOpen={open}
        loading={isPending}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        {...confirmProps}
      />
    </>
  );
}
