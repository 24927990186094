import { Alert, Typography, useTheme } from "@mui/material";
import React from "react";

interface ErrorPayload {
  message: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isErrorPayload(obj: any): obj is ErrorPayload {
  return obj && typeof obj.message === "string";
}

export const ErrorAlert = React.forwardRef<HTMLDivElement, ErrorPayload>(
  ({ message }: ErrorPayload, ref): React.ReactElement => {
    const theme = useTheme();
    return (
      <Alert ref={ref} severity="error" variant="filled">
        <Typography fontWeight={theme.typography.fontWeightMedium}>{message}</Typography>
      </Alert>
    );
  },
);
