import { DeleteWithConfirmButton, FunctionField, useDataProvider } from "react-admin";
import { Clear } from "@mui/icons-material";
import { EkieDataProvider } from "../../../providers/dataProvider";
import { Member } from "../../members/api";
import { ConfirmButton } from "../../../components/ConfirmButton";
import { memberRepresentation } from "../../members/format";

export function MemberDeleteButton() {
  const dataProvider = useDataProvider<EkieDataProvider>();
  return (
    <FunctionField<Member>
      sortable={false}
      render={(member) => {
        if (member.can_be_cancelled) {
          return (
            <ConfirmButton
              buttonProps={{
                startIcon: <Clear />,
                color: "error",
                label: "member.cancel",
              }}
              dataProviderAction={() => dataProvider.cancelMember(member.id)}
              confirmProps={{
                confirmColor: "warning",
                title: "member.cancel.title",
                content: "member.cancel.content",
                translateOptions: { name: memberRepresentation(member) },
                confirm: "member.cancel",
              }}
              successMessage="member.cancel.success"
            />
          );
        }
        if (member.can_be_deleted) {
          return (
            <DeleteWithConfirmButton
              translateOptions={{ name: memberRepresentation(member) }}
              record={member}
              confirmColor="warning"
              confirmContent="member.delete.content"
              confirmTitle="member.delete.title"
              redirect={false}
            />
          );
        }
        return null;
      }}
    />
  );
}
