import { BooleanInput, required, SelectInput, useDataProvider } from "react-admin";
import { Grid } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Consultation, ConsultationCancelInput } from "../api";
import { AppointmentCancelReason } from "../../lawyer/api";
import ActionDialogButton from "../../../components/ActionDialogButton";
import { EkieDataProvider } from "../../../providers/dataProvider";

const cancelReasons: { id: AppointmentCancelReason; name: string }[] = [
  { id: "lawyer_unavailable", name: "consultation.edit.appointment.status.lawyer_unavailable" },
  { id: "lawyer_recused", name: "consultation.edit.appointment.status.lawyer_recused" },
  { id: "lawyer_noshow", name: "consultation.edit.appointment.status.lawyer_noshow" },
  { id: "member_unavailable", name: "consultation.edit.appointment.status.member_unavailable" },
  { id: "member_dispute", name: "consultation.edit.appointment.status.member_dispute" },
  { id: "member_noshow", name: "consultation.edit.appointment.status.member_noshow" },
  { id: "member_order_cancellation", name: "consultation.edit.appointment.status.member_order_cancellation" },
  { id: "assignment_error", name: "consultation.edit.appointment.status.assignment_error" },
  { id: "other", name: "consultation.edit.appointment.status.other" },
];

type CancelConsultationFormData = Consultation & ConsultationCancelInput;

export default function CancelDialogButton() {
  const dataProvider = useDataProvider<EkieDataProvider>();
  return (
    <ActionDialogButton<CancelConsultationFormData, ConsultationCancelInput>
      dialogTitle="consultation.edit.cancel.dialog.title"
      buttonProps={{
        label: "consultation.edit.cancel.button.label",
        color: "error",
        startIcon: <CancelOutlinedIcon />,
        size: "medium",
      }}
      dataProviderAction={dataProvider.cancelConsultation}
      paramTransformer={(consultation) => {
        return {
          consultation_id: consultation.id,
          incident_type: consultation.incident_type,
          to_reschedule: consultation.to_reschedule,
        };
      }}
    >
      <Grid sx={{ width: 1 }}>
        <SelectInput
          source="incident_type"
          label="consultation.edit.cancel.reason.label"
          choices={cancelReasons}
          validate={required()}
        />
        <BooleanInput source="to_reschedule" defaultValue label="consultation.edit.cancel.to_reschedule.label" />
      </Grid>
    </ActionDialogButton>
  );
}
