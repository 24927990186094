import { ResourceCallbacks } from "ra-core/src/dataProvider/withLifecycleCallbacks";
import { add } from "date-fns";
import { axiosInstance } from "../../providers/api";

export type AssignationStatus = "rejected" | "pending" | "accepted";

export type ArchivedReasons = "other" | "excluded_domain" | "too_many_questions_asked" | "no_question_asked";

export type UnifiedStatus =
  | "awaiting_payment"
  | "awaiting_manual_check"
  | "pending_qualification"
  | "assigned"
  | "assignment_rejected"
  | "assignment_accepted"
  | "answered"
  | "answer_validated"
  | "additional_request_in_progress"
  | "additional_request_answered"
  | "reviewed"
  | "archived";

export interface Question {
  id: number;
  content: string;
  created_at: string;
  law_domain_id: string | null;
  can_transition_to_archived: boolean;
  can_transition_to_assigned: boolean;
  can_transition_to_answer_validated: boolean;
  can_transition_back_to_assignment_accepted: boolean;
  customer_id: number | null;
  member_id: number;
  assignation_ids: string[];
  remaining_time_to_answer_seconds: number | null;
  answer_deadline_at: string;
  unified_status: UnifiedStatus;
  tag_id: string | null;
  assigned_lawyer_id: number | null;
  archived_reason: ArchivedReasons | null;
  archived_at: string | null;
  finalized_answer_id: number | null;
  product_id: number | null;
  has_conflicting_tag: boolean;
}

export interface AnswerRating {
  id: number;
  answer_id: number;
  note: number;
  comment: string | null;
}

export interface QuestionInput {
  question_id: number;
}

export interface QuestionAssignInput extends QuestionInput {
  tag_id: string;
  lawyer_id: number;
}

export interface QuestionArchiveInput extends QuestionInput {
  tag_id: string | null;
  reason: ArchivedReasons;
}

export interface Assignation {
  id: string;
  lawyer_id: number;
  question_id: number;
  status: AssignationStatus;
  source: string;
  reject_reason: string | null;
}

export interface Tag {
  id: number;
  name: string;
}

export type AnswerStatus = "draft" | "taken_down" | "pending_moderation" | "published";

export interface Answer {
  id: number;
  lawyer_id: number;
  question_id: number;
  created_at: string;
  content: string;
  additional_request_id: string | null;
  status: AnswerStatus;
}

export interface AdditionalRequest {
  id: string;
  request: string;
  created_at: string;
  request_answer: string | null;
  answered_at: string | null;
  lawyer_id: number;
}

export interface AiSuggestionAnswer {
  id: string;
  question_id: number;
  created_at: string;
  content: string;
}

export const questionProviderMethods = {
  async assignQuestion({ question_id, tag_id, lawyer_id }: QuestionAssignInput) {
    return axiosInstance.post(`/staff/questions/${question_id}/assign`, {
      tag_id,
      assigned_lawyer_id: lawyer_id,
    });
  },

  async validateQuestionPendingAnswer({ question_id }: QuestionInput) {
    return axiosInstance.post(`/staff/questions/${question_id}/validate-pending-answer`);
  },

  async draftValidatedAnswer({ question_id }: QuestionInput) {
    return axiosInstance.post(`/staff/questions/${question_id}/draft-validated-answer`);
  },

  async archiveQuestion({ question_id, reason, tag_id }: QuestionArchiveInput) {
    return axiosInstance.post(`/staff/questions/${question_id}/archive`, {
      tag_id,
      reason,
    });
  },
};

export type QuestionDataProvider = typeof questionProviderMethods;

export const questionHandlers: ResourceCallbacks[] = [
  {
    resource: "staff/questions",
    beforeGetList: ({ filter, ...params }) => {
      const updatedFilter = { ...filter };
      if (!("unified_status" in filter) && updatedFilter.is_assigned_or_assignment_accepted) {
        updatedFilter.unified_status = ["assignment_accepted", "assigned"];
      }

      if ("answer_deadline" in updatedFilter) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { answer_deadline } = updatedFilter;
        delete updatedFilter.answer_deadline;
        switch (answer_deadline) {
          case "gte_3h":
            updatedFilter.answer_deadline_at_from = add(new Date(), { hours: 3 }).toISOString();
            break;
          case "lte_3h":
            updatedFilter.answer_deadline_at_to = add(new Date(), { hours: 3 }).toISOString();
            break;
          case "lte_1h":
            updatedFilter.answer_deadline_at_to = add(new Date(), { hours: 1 }).toISOString();
            break;
          default:
        }
      }

      return Promise.resolve({ ...params, filter: updatedFilter });
    },
  },
];
