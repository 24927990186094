import React, { ReactElement } from "react";
import { Labeled, ReferenceField, TextField } from "react-admin";
import { Card, CardContent, Grid } from "@mui/material";

export default function MemberCard(): ReactElement {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={1} width={400}>
          <Grid item xs={4}>
            <Labeled>
              <ReferenceField reference="staff/members" source="member_id" label="aside.member.id.label">
                <TextField source="id" />
              </ReferenceField>
            </Labeled>
          </Grid>
          <Grid item xs={8}>
            <Labeled>
              <ReferenceField reference="staff/members" source="member_id" label="aside.member.fullName.label">
                <TextField source="full_name" emptyText="common.field.missingValue" />
              </ReferenceField>
            </Labeled>
          </Grid>
          <Grid item xs={12}>
            <Labeled>
              <ReferenceField reference="staff/members" source="member_id" label="aside.member.email.label">
                <TextField source="email" />
              </ReferenceField>
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled>
              <ReferenceField
                reference="staff/customers"
                source="customer_id"
                label="aside.member.customer.name.label"
                emptyText="common.field.missingValue"
              />
            </Labeled>
          </Grid>
          <Grid item xs={6}>
            <Labeled>
              <ReferenceField
                reference="staff/members"
                source="member_id"
                label="aside.member.customer.source.label"
                link={false}
              >
                <TextField source="partner_customer_name" emptyText="common.field.missingValue" />
              </ReferenceField>
            </Labeled>
          </Grid>
          <Grid item xs={12}>
            <Labeled>
              <ReferenceField
                reference="staff/products"
                source="product_id"
                label="aside.member.customer.product.label"
                emptyText="common.field.missingValue"
                link={(record) => `/staff/customers/${record.customer_id}/show`}
              />
            </Labeled>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
