import {
  TextField,
  Labeled,
  useRecordContext,
  Link,
  useTranslate,
  useGetList,
  usePermissions,
  ReferenceField,
} from "react-admin";
import { CardContent, Card, Stack } from "@mui/material";
import React from "react";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import DateTimeField from "../../components/DateTimeField";
import { Member } from "./api";
import { ROLE_OPERATIONS } from "../../providers/authProvider";

interface MemberRelatedResourceCountProps {
  resource: string;
  label: string;
  icon: React.ReactNode;
}

function MemberRelatedResourceCount({
  resource,
  label,
  icon,
}: MemberRelatedResourceCountProps): React.ReactElement | null {
  const translate = useTranslate();
  const record = useRecordContext<Member>();
  const { total, isPending, error } = useGetList(resource, { filter: { member_id: record?.id } });

  if (!record || !record.email || isPending || error || total === 0) return null;

  return (
    <Stack direction="row" spacing={1}>
      {icon}
      <Link
        variant="body2"
        to={{
          pathname: `/${resource}`,
          search: `filter=${JSON.stringify({
            member_id: record.id,
          })}`,
        }}
      >
        {translate(label, { smart_count: total })}
      </Link>
    </Stack>
  );
}

export default function MemberAside(): React.ReactElement | null {
  const { permissions } = usePermissions<string[]>();

  return (
    <Stack display={{ xs: "none", lg: "block" }} sx={{ width: 400 }} ml={2} spacing={2}>
      <Card>
        <CardContent>
          <Stack width={400} spacing={2}>
            <Labeled>
              <TextField source="id" label="member.aside.id" />
            </Labeled>
            <Labeled label="member.customer">
              <ReferenceField reference="staff/customers" source="customer_id" emptyText="common.field.missingValue" />
            </Labeled>
            <Labeled label="member.source">
              <TextField source="partner_customer_name" emptyText="common.field.missingValue" />
            </Labeled>
            <Labeled>
              <DateTimeField showTime source="signup_at" label="member.aside.signup" />
            </Labeled>
            <Labeled>
              <DateTimeField
                showTime
                source="last_login_at"
                label="member.aside.lastLogin"
                emptyText="common.field.missingValue"
              />
            </Labeled>
            {permissions?.includes(ROLE_OPERATIONS) && (
              <>
                <MemberRelatedResourceCount
                  resource="staff/questions"
                  icon={<QuestionAnswerOutlinedIcon color="secondary" />}
                  label="member.aside.questionCount"
                />
                <MemberRelatedResourceCount
                  resource="staff/consultations"
                  icon={<PhoneOutlinedIcon color="secondary" />}
                  label="member.aside.consultationCount"
                />
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}
